
export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "Courses",
    desc: "We provide courses from zero to master.Our comprehensive courses provide personalized guidance, practical insights, and hands-on experience to help you master programming skills regardless of your age or experience level.",
  },
  {
    id: 2,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Earn A Certificates",
    desc: "Earn a certificate through our platform and unlock new opportunities for your future. Gain valuable skills at your own pace and receive recognition for your achievements. Take the first step towards advancing your career today!",
  },
  {
    id: 3,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Learn with Expert",
    desc: "Embark your journey with us by learning from experts in the field.Whether you're a curious beginner or a seasoned enthusiast, our expert-led approach ensures that you receive the support and mentorship needed to excel in the world of coding.",
  },
]
export const awrapper = [
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "3,000",
    title: "SUCCESS STORIES",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "320",
    title: "TRUSTED TUTORS",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "1,000",
    title: "SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "587",
    title: "COURSES",
  },
]

export const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    coursesName: "BASIC",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "STEP-1",

      },
    ],
    sub: [
      "Block Programming",
      "Basic Python",
      "Basic R",
      "MS Excel",
      "Introduction AI",
      "and Many More"
    ],
    priceAll: "₹2000",
    pricePer: "month",
  },
  {
    id: 2,
    cover: "../images/courses/c2.png",
    coursesName: "Advance",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "STEP-2",
        totalTime: "STEP-2",
      },
    ],
    sub: [
      "Artificial Intelligence",
      "Advance Java",
      "Python",
      "HTML/CSS",
      "Database Management System",
      "and Many More"
    ],
    priceAll: "₹3500",
    pricePer: "month",
  },
  {
    id: 3,
    cover: "../images/courses/c3.png",
    coursesName: "Advance+",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "STEP-3",
        totalTime: "STEP-3",
      },
    ],
    sub: [
      "AI/ML",
      "Advance Frameworks",
      "Backend Programming",
      "Frontend Programming",
      "Data Science/Analyst",
      "and Many More"
    ],
    priceAll: "₹5000 ",
    pricePer: "month",
  },
  {
    id: 4,
    cover: "../images/courses/c4.png",
    coursesName: "Introducing to Programming with WordPress",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Prabin Thapa Mager",
        totalTime: "20 lectures (20 hrs)",
      },
    ],
    priceAll: "$30 All Course",
    pricePer: "$3 per month",
  },
  {
    id: 5,
    cover: "../images/courses/c5.png",
    coursesName: "Introducing to Programming with ReactJS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    priceAll: "$300 All Course",
    pricePer: "$30 per month",
  },
  {
    id: 6,
    cover: "../images/courses/c6.png",
    coursesName: "Learn Frontend Programming Language",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "200 lectures (300 hrs)",
      },
    ],
    priceAll: "$500 All Course",
    pricePer: "$80 per month",
  },
  {
    id: 7,
    cover: "../images/courses/c7.png",
    coursesName: "Introducing to with HTML / CSS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Price",
        totalTime: "20 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$2 per month",
  },
  {
    id: 8,
    cover: "../images/courses/c8.png",
    coursesName: "Introducing to with JAVA",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "80 lectures (200 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$40 per month",
  },
  {
    id: 9,
    cover: "../images/courses/c9.png",
    coursesName: "Introducing to with HTML / CSS / JAVASCRIPT",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$3 per month",
  },
]
export const certificates = [
  {
    cover: "./images/certificate/iaf.png",
  },
  {
    cover: "./images/certificate/iso.png",
  }
]
export const online = [
  {
    "id": 1,
    "cover": "./images/courses/online/o3.png",
    "hoverCover": "./images/courses/online/o3.1.png",
    "courseName": "Artificial Intelligence and Machine Learning",
    "course": "Enroll Now",
    "price": "₹14990 only"
  },
  {
    "id": 2,
    "cover": "./images/courses/online/o5.png",
    "hoverCover": "./images/courses/online/o5.1.png",
    "courseName": "Data Science",
    "course": "Enroll Now",
    "price": "₹14990 only"
  },
  {
    "id": 3,
    "cover": "./images/courses/online/o12.png",
    "hoverCover": "./images/courses/online/o12.1.png",
    "courseName": "DevOps and Agile Practices",
    "course": "Enroll Now",
    "price": "₹9990 only"
  },
  {
    "id": 4,
    "cover": "./images/courses/online/o14.png",
    "hoverCover": "./images/courses/online/o14.1.png",
    "courseName": "Internet of Things (IoT)",
    "course": "Enroll Now",
    "price": "₹11990 only"
  },
  {
    "id": 5,
    "cover": "./images/courses/online/o15.png",
    "hoverCover": "./images/courses/online/o15.1.png",
    "courseName": "Cloud Computing",
    "course": "Enroll Now",
    "price": "₹11990 only"
  },
  {
    "id": 6,
    "cover": "./images/courses/online/o6.png",
    "hoverCover": "./images/courses/online/o6.1.png",
    "courseName": "Generative AI",
    "course": "Enroll Now",
    "price": "₹10500 only"
  },
  {
    "id": 7,
    "cover": "./images/courses/online/o13.png",
    "hoverCover": "./images/courses/online/o13.1.png",
    "courseName": "Cyber Security",
    "course": "Enroll Now",
    "price": "₹9990 only"
  },
  {
    "id": 8,
    "cover": "./images/courses/online/o9.png",
    "hoverCover": "./images/courses/online/o9.1.png",
    "courseName": "GitHub",
    "course": "Enroll Now",
    "price": "₹1499 only"
  },
  {
    "id": 9,
    "cover": "./images/courses/online/o1.png",
    "hoverCover": "./images/courses/online/o1.1.png",
    "courseName": "Software Development and Programming",
    "course": "Enroll Now",
    "price": "₹14990 only"
  },
  {
    "id": 10,
    "cover": "./images/courses/online/o11.png",
    "hoverCover": "./images/courses/online/o11.1.png",
    "courseName": "Practice Interview with an Expert",
    "course": "Enroll Now",
    "price": "₹299 only"
  }
]

export const team = [
  {
    cover: "./images/team/t1.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t2.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t3.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t4.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t5.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t6.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t7.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t8.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
]
export const price = [
  {
    name: "BASIC PLAN",
    price: "49K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "BEGINNER PLAN",
    price: "79K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "PREMIUM PLAN",
    price: "109k",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "ULTIMATE PLAN",
    price: "149K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
]
export const faq = [
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
]
export const blog = [
  {
    id: 1,
    type: "admin",
    date: "JAN. 18, 2021",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b1.webp",
  },
  {
    id: 2,
    type: "admin",
    date: "API. 25, 2022",
    com: "5 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b2.webp",
  },
  {
    id: 3,
    type: "user",
    date: "MAY. 15, 2022",
    com: "10 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b3.webp",
  },
  {
    id: 4,
    type: "admin",
    date: "JAN. 02, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b4.webp",
  },
  {
    id: 5,
    type: "admin",
    date: "DEC. 14, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b5.webp",
  },
  {
    id: 6,
    type: "user",
    date: "JAN. 18, 2021",
    com: "12 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b6.webp",
  },
]
export const testimonal = [
  {
    id: 1,
    name: "Neha Sharma",
    post: "Sr. Software Engineer",
    desc: "Neha is currently employed at American Express, where she brings over six years of extensive experience in Full Stack development. Throughout her career, she has honed her skills in both front-end and back-end technologies, enabling her to design and implement robust, scalable, and user-friendly applications. Neha's expertise spans various programming languages and frameworks, allowing her to work seamlessly across the entire development lifecycle.",
    cover: "./images/testo/neha.webp",
    linkdin: "https://www.linkedin.com/in/neha-sharma-56b91914a/"
  },
  {
    id: 2,
    name: "Gourav Sharma",
    post: "Software Engineer",
    desc: "Gaurav is a skilled professional specializing in Java and React, with a solid background in software development. His expertise in these technologies has enabled him to build dynamic, responsive, and high-performance web applications that meet modern industry standards. During his tenure at Bharti Airtel, Gaurav was instrumental in developing and maintaining critical software solutions that supported the company’s vast operations. His ability to work with complex systems and deliver robust applications under tight deadlines made him a key contributor to the team.",
    cover: "./images/testo/gu.webp",
    linkdin: "https://www.linkedin.com/in/gourav-sharma-3662b1185/"
  },
  {
    id: 3,
    name: "Satyam Pandey",
    post: "Software Engineer",
    desc: "Satyam is a software developer at Panamoure with a strong expertise in Data Science, Artificial Intelligence, and the Microsoft Power Platform, for which he holds a certification. His technical skills enable him to develop sophisticated data-driven solutions and leverage AI to enhance business processes. Satyam's proficiency in these cutting-edge technologies makes him a valuable asset to his team, driving innovation and efficiency in the projects he undertakes.",
    cover: "./images/testo/sat.webp",
    linkdin: "https://www.linkedin.com/in/satyam-pandey-2b05741ba/"
  },
  {
    id: 3,
    name: "Sheelender Sharma",
    post: "Senior Advisor",
    desc: "Sheelender is a certified Data Science engineer with a specialization in Artificial Intelligence. His expertise in AI allows him to develop advanced algorithms and models that drive data-driven decision-making and innovation. Sheelender's strong foundation in Data Science, combined with his AI specialization, positions him as a key contributor in the field, capable of tackling complex challenges and delivering impactful solutions.",
    cover: "./images/testo/shilu.webp",
    linkdin: "https://www.linkedin.com/in/sheelendersharma/"
  },
  {
    id: 4,
    name: "Shreya Anand",
    post: "Senior Software Developer",
    desc: "Shreya is an experienced Backend Developer with extensive expertise in designing and building robust software solutions. Proficient in Java, Spring Boot, Microservices architecture, and DevOps methodologies, bringing a blend of technical skill and engineering excellence to every project. A passionate problem-solver with a proven track record in the software industry, dedicated to delivering scalable and efficient backend systems.",
    cover: "./images/testo/shreya.webp",
    linkdin: "https://www.linkedin.com/in/shreya-anand-412b19134/"
  },
]


export const pythonQuestions = [
  {
    id: 1,
    type: 'mcq',
    name: "Question 1: What is the output of the following Python code?",
    code: "print(3 * '7')",
    option: ["1. 777", "2. 21", "3. 777", "4. ERROR"]
  },
  {
    id: 2,
    type: 'mcq',
    name: "Question 2: Which of the following is used to comment a single line in R?",
    code: "",
    option: ["1. //", "2. /**/", "3. #", "4. <!-- -->"]
  },
  {
    id: 3,
    type: 'mcq',
    name: "Question 3: What function is used to find the length of a list in Python?",
    code: "",
    option: ["1. size()", "2. length()", "3. len()", "4. count()"]
  },
  {
    id: 4,
    type: 'mcq',
    name: "Question 4: In R, which keyword is Used for conditional execution of code?",
    code: "",
    option: ["1. if, else, else if", "2. for", "3. while", "4. repeat, break"]
  },
  {
    id: 5,
    type: 'mcq',
    name: "Question 5: What is the correct syntax to create a function in Python?",
    code: "",
    option: ["1. def function_name():", "2. function function_name():", "3. create function_name():", "4. func function_name():"]
  },
  {
    id: 6,
    type: 'intermediateMcq',
    name: "Question 6: What does the following Python list slicing return?",
    code: "list = [1, 2, 3, 4, 5] <br/> print(list[1:4])",
    option: ["1. [1, 2, 3, 4]", "2. [2, 3, 4]", "3. [1, 2, 3]", "4. [2, 3, 4, 5]"]
  },
  {
    id: 7,
    type: 'intermediateMcq',
    name: "Question 7: In R, which function is used to  Display the explanation box?",
    code: "",
    option: ["1. fill", "2. legend", "3. lambda", "4. Display"]
  },
  {
    id: 8,
    type: 'intermediateMcq',
    name: "Question 8: Which of the following is not a keyword in Python?",
    code: "",
    option: ["1. eval", "2. break", "3. end", "4. python"]
  },
  {
    id: 9,
    type: 'intermediateMcq',
    name: "Question 9: In R, what is the output of the following code?",
    code: "x <- c(1, 2, 3, 4) <br/> y <- x * 2 <br/> print(y)",
    option: ["1. 1 2 3 4", "2. 2 4 6 8", "3. 1 4 9 16", "4. error"]
  },
  {
    id: 10,
    type: 'intermediateMcq',
    name: "Question 10: Which of the following is a mutable data type in Python?",
    code: "",
    option: ["1. tuple", "2. list", "3. string", "4. int"]
  },
  {
    id: 11,
    type: 'intermediateMcq',
    name: "Question 11: What will be the output of the following R code?",
    code: "v <- c(a, b, c, d) <br/> v[2:3] ",
    option: ["1. a b", "2. b c", "3. c d", "4. b c d"]
  },
  {
    id: 12,
    type: 'intermediateMcq',
    name: "Question 12: Which keyword is used to handle exceptions in Python?",
    code: "",
    option: ["1. try", "2. catch", "3. except", "4. finally"]
  },
  {
    id: 13,
    type: 'intermediateMcq',
    name: "Question 13: Function returns the square root of a number:",
    code: "",
    option: ["1. sqrt()", "2. square()", "3. sqr()", "4. root()"]
  },
  {
    id: 14,
    type: 'intermediateMcq',
    name: "Question 14: What does the following Python code do?",
    code: "x = [1, 2, 3, 4] <br/> print(x.pop())",
    option: ["1. Removes and returns the first element", "2. Removes and returns the last element",
      "3. Returns the length of the list", "4. Raises an error"]
  },
  {
    id: 15,
    type: 'intermediateMcq',
    name: "Question 15: In R, what is the purpose of the (names.arg)?",
    code: "",
    option: ["1. defines the names of each observation in the x-axis or y-axis", "2. To get the first few elements of a data frame",
      "3. To get the middle elements of a data frame", "To change the bar texture"]
  },
  {
    id: 16,
    type: 'program',
    name: "Question 1: Write a Python function that takes a list of numbers and returns a new list containing only the even numbers.",
    code: "",
    option: []
  },
  {
    id: 17,
    type: 'program',
    name: "Question 2: Make Bar Plot with all labels.",
    code: "numbers <- c(5, 12, 3, 18, 7)",
    option: []
  },
  {
    id: 18,
    type: 'program',
    name: "Question 3: Write a Python function to calculate the factorial of a given number.",
    code: "",
    option: []
  },
  {
    id: 19,
    type: 'program',
    name: "Question 4: Make line plot:",
    code: "a <- c(5, 12, 3, 18, 7) <br/> b<- c(6, 15, 5, 20, 10) ",
    option: []
  },
  {
    id: 20,
    type: 'program',
    name: "Question 5: Write a Python function to reverse a string.",
    code: "",
    option: []
  },
]