import React, { useEffect } from "react"
import AboutCard from "../about/AboutCard"
import Hblog from "./Hblog"
import HAbout from "./HAbout"
import Hero from "./hero/Hero"
import Hprice from "./Hprice"
import Testimonal from "./testimonal/Testimonal"
import Certificates from "./Certificates"

const Home = ({ setName }) => {

  useEffect(() => {
    setName('');
  })

  return (
    <>
      <Hero />

      <AboutCard setName={setName} />
      <Certificates />
      <HAbout setName={setName} />
      <Testimonal setName={setName} />
      {/* <Hblog /> */}
      {/* <Hprice /> */}
    </>
  )
}

export default Home
